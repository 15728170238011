.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
  }

  .images-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 20%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); // property, duration, transition type
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0,
        rgb(30, 96, 145, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 10px;
      padding: 0 23px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #1e6091;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #1e6091;
    }

    &:after {
      content: '';
      background: linear-gradient(180deg, #1e6091, #ffffff50);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.wavesPort {
  bottom: 0;
  position: fixed;
  width: 100%;
  margin-bottom: -100px;
  animation: fadeIn 5s;
  height: 100%;
  z-index: -1;

  .path-0 {
    animation: pathAnim-0 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 1s;
  }

  @keyframes pathAnim-0 {
    0% {
      d: path(
        'M 0,600 C 0,600 0,200 0,200 C 85.63998625901755,231.17347990381313 171.2799725180351,262.34695980762626 237,263 C 302.7200274819649,263.65304019237374 348.52009618687737,233.78564067330814 410,213 C 471.47990381312263,192.21435932669186 548.6396427344556,180.51047749914122 627,165 C 705.3603572655444,149.48952250085878 784.9213328753005,130.1724493301271 847,138 C 909.0786671246995,145.8275506698729 953.6750257643421,180.79972518035038 1024,197 C 1094.324974235658,213.20027481964962 1190.378564067331,210.62864994847135 1264,208 C 1337.621435932669,205.37135005152865 1388.8107179663346,202.68567502576434 1440,200 C 1440,200 1440,600 1440,600 Z'
      );
    }
    25% {
      d: path(
        'M 0,600 C 0,600 0,200 0,200 C 72.76880796976982,171.18790793541737 145.53761593953965,142.37581587083474 208,151 C 270.46238406046035,159.62418412916526 322.61834421161115,205.68464445207832 384,201 C 445.38165578838885,196.31535554792168 515.9890072140158,140.88560632085196 590,151 C 664.0109927859842,161.11439367914804 741.4256269323255,236.7729302645139 810,250 C 878.5743730676745,263.2270697354861 938.3084850566818,214.0226726210924 1006,181 C 1073.6915149433182,147.9773273789076 1149.3404328409479,131.13637925111647 1223,137 C 1296.6595671590521,142.86362074888353 1368.329783579526,171.43181037444177 1440,200 C 1440,200 1440,600 1440,600 Z'
      );
    }
    50% {
      d: path(
        'M 0,600 C 0,600 0,200 0,200 C 61.113019580900044,154.95774647887322 122.22603916180009,109.91549295774647 189,134 C 255.7739608381999,158.08450704225353 328.2088629336998,251.2957746478873 398,256 C 467.7911370663002,260.7042253521127 534.9385091034009,176.90140845070422 605,148 C 675.0614908965991,119.09859154929578 748.0371006526967,145.09859154929575 829,168 C 909.9628993473033,190.90140845070425 998.9130882858124,210.7042253521127 1066,212 C 1133.0869117141876,213.2957746478873 1178.3105462040537,196.0845070422535 1237,191 C 1295.6894537959463,185.9154929577465 1367.844726897973,192.95774647887325 1440,200 C 1440,200 1440,600 1440,600 Z'
      );
    }
    75% {
      d: path(
        'M 0,600 C 0,600 0,200 0,200 C 79.61456544142905,239.25867399519066 159.2291308828581,278.5173479903813 219,271 C 278.7708691171419,263.4826520096187 318.69804190999656,209.18928203366542 378,200 C 437.30195809000344,190.81071796633458 515.9787014771557,226.72552387495708 596,217 C 676.0212985228443,207.27447612504292 757.387152181381,151.90862246650636 825,143 C 892.612847818619,134.09137753349364 946.4726897973205,171.63998625901752 1018,195 C 1089.5273102026795,218.36001374098248 1178.7220886293371,227.5314324974236 1252,226 C 1325.2779113706629,224.4685675025764 1382.6389556853314,212.23428375128822 1440,200 C 1440,200 1440,600 1440,600 Z'
      );
    }
    100% {
      d: path(
        'M 0,600 C 0,600 0,200 0,200 C 85.63998625901755,231.17347990381313 171.2799725180351,262.34695980762626 237,263 C 302.7200274819649,263.65304019237374 348.52009618687737,233.78564067330814 410,213 C 471.47990381312263,192.21435932669186 548.6396427344556,180.51047749914122 627,165 C 705.3603572655444,149.48952250085878 784.9213328753005,130.1724493301271 847,138 C 909.0786671246995,145.8275506698729 953.6750257643421,180.79972518035038 1024,197 C 1094.324974235658,213.20027481964962 1190.378564067331,210.62864994847135 1264,208 C 1337.621435932669,205.37135005152865 1388.8107179663346,202.68567502576434 1440,200 C 1440,200 1440,600 1440,600 Z'
      );
    }
  }

  .path-1 {
    animation: pathAnim-1 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 2s;
  }
  @keyframes pathAnim-1 {
    0% {
      d: path(
        'M 0,600 C 0,600 0,400 0,400 C 53.040192373754735,438.19306080384746 106.08038474750947,476.3861216076949 176,469 C 245.91961525249053,461.6138783923051 332.71865338371686,408.6485743730677 420,384 C 507.28134661628314,359.3514256269323 595.0450017176229,363.0195809000343 652,385 C 708.9549982823771,406.9804190999657 735.1013397457918,447.27310202679496 795,460 C 854.8986602542082,472.72689797320504 948.54963929921,457.888010992786 1026,437 C 1103.45036070079,416.111989007214 1164.7001030573688,389.1748540020611 1231,382 C 1297.2998969426312,374.8251459979389 1368.6499484713156,387.41257299896944 1440,400 C 1440,400 1440,600 1440,600 Z'
      );
    }
    25% {
      d: path(
        'M 0,600 C 0,600 0,400 0,400 C 69.85022329096529,373.6441085537616 139.70044658193058,347.28821710752317 206,357 C 272.2995534180694,366.71178289247683 335.04843696324286,412.4912401236689 406,420 C 476.95156303675714,427.5087598763311 556.105805565098,396.74682239780145 630,407 C 703.894194434902,417.25317760219855 772.5283407763654,468.5214702851254 849,455 C 925.4716592236346,441.4785297148746 1009.7808313294402,363.16729646169694 1065,365 C 1120.2191686705598,366.83270353830306 1146.3483339058741,448.8093438680866 1204,468 C 1261.6516660941259,487.1906561319134 1350.825833047063,443.59532806595666 1440,400 C 1440,400 1440,600 1440,600 Z'
      );
    }
    50% {
      d: path(
        'M 0,600 C 0,600 0,400 0,400 C 68.45276537272414,436.10786671246996 136.9055307454483,472.2157334249399 216,467 C 295.0944692545517,461.7842665750601 384.83064239093096,415.24493301271036 442,412 C 499.16935760906904,408.75506698728964 523.7718996908278,448.8045345242184 587,441 C 650.2281003091722,433.1954654757816 752.0817588457575,377.53692889041565 830,370 C 907.9182411542425,362.46307110958435 961.9010649261422,403.04774991411887 1017,417 C 1072.0989350738578,430.95225008588113 1128.3139814496737,418.2720714531089 1199,411 C 1269.6860185503263,403.7279285468911 1354.8430092751632,401.86396427344556 1440,400 C 1440,400 1440,600 1440,600 Z'
      );
    }
    75% {
      d: path(
        'M 0,600 C 0,600 0,400 0,400 C 56.43490209549984,381.1940913775335 112.86980419099967,362.388182755067 174,359 C 235.13019580900033,355.611817244933 300.9556853315011,367.64136035726557 385,363 C 469.0443146684989,358.35863964273443 571.3074544829956,337.0463758158709 646,334 C 720.6925455170044,330.9536241841291 767.8144967365166,346.1731363792511 830,370 C 892.1855032634834,393.8268636207489 969.434558570938,426.2610786671247 1042,425 C 1114.565441429062,423.7389213328753 1182.447268979732,388.78254895225007 1248,379 C 1313.552731020268,369.21745104774993 1376.776365510134,384.60872552387497 1440,400 C 1440,400 1440,600 1440,600 Z'
      );
    }
    100% {
      d: path(
        'M 0,600 C 0,600 0,400 0,400 C 53.040192373754735,438.19306080384746 106.08038474750947,476.3861216076949 176,469 C 245.91961525249053,461.6138783923051 332.71865338371686,408.6485743730677 420,384 C 507.28134661628314,359.3514256269323 595.0450017176229,363.0195809000343 652,385 C 708.9549982823771,406.9804190999657 735.1013397457918,447.27310202679496 795,460 C 854.8986602542082,472.72689797320504 948.54963929921,457.888010992786 1026,437 C 1103.45036070079,416.111989007214 1164.7001030573688,389.1748540020611 1231,382 C 1297.2998969426312,374.8251459979389 1368.6499484713156,387.41257299896944 1440,400 C 1440,400 1440,600 1440,600 Z'
      );
    }
  }
}
